import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    if (!window.localStorage.cookieConsent) {
      $(this.scope.element).addClass('show');
    }
  }

  close () {
    $(this.scope.element).removeClass('show');
    window.localStorage.cookieConsent = true;
  }
}
